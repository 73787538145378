// extracted by mini-css-extract-plugin
export var _ = "PlasmicMainLandingPage2-module--_--MQ2Vv";
export var about = "PlasmicMainLandingPage2-module--about--I6vsr";
export var allCustomAssets = "PlasmicMainLandingPage2-module--allCustomAssets--sZSIR";
export var button___30SMx = "PlasmicMainLandingPage2-module--button___30SMx--afwHS";
export var button__g1Dns = "PlasmicMainLandingPage2-module--button__g1Dns--RN9w+";
export var button__maey7 = "PlasmicMainLandingPage2-module--button__maey7--NhuOQ";
export var button__mnbWs = "PlasmicMainLandingPage2-module--button__mnbWs--PNNqh";
export var button__qQfe = "PlasmicMainLandingPage2-module--button__qQfe--Sk9OA";
export var button__wZa7 = "PlasmicMainLandingPage2-module--button__wZa7--qEnnT";
export var button__zrUc5 = "PlasmicMainLandingPage2-module--button__zrUc5--FFPUj";
export var column___2GeqB = "PlasmicMainLandingPage2-module--column___2GeqB--I8Fgm";
export var column___3Nzej = "PlasmicMainLandingPage2-module--column___3Nzej---GwZn";
export var column___3Qpbn = "PlasmicMainLandingPage2-module--column___3Qpbn--GTQ+c";
export var column___7NYzR = "PlasmicMainLandingPage2-module--column___7NYzR--HgS7U";
export var column__iIqUu = "PlasmicMainLandingPage2-module--column__iIqUu--Yepbj";
export var column__qLNje = "PlasmicMainLandingPage2-module--column__qLNje--8Wpss";
export var column__qrKTm = "PlasmicMainLandingPage2-module--column__qrKTm--zTJBS";
export var column__tCaJp = "PlasmicMainLandingPage2-module--column__tCaJp--MVq4Z";
export var column__zjdUz = "PlasmicMainLandingPage2-module--column__zjdUz--lAe4I";
export var columns__hGjnu = "PlasmicMainLandingPage2-module--columns__hGjnu---peHc";
export var columns__mZoK8 = "PlasmicMainLandingPage2-module--columns__mZoK8--phWAE";
export var columns__rb4Vx = "PlasmicMainLandingPage2-module--columns__rb4Vx--VPRyB";
export var columns__tgBxr = "PlasmicMainLandingPage2-module--columns__tgBxr--n8xtX";
export var contactUs = "PlasmicMainLandingPage2-module--contactUs--S0mPz";
export var creationProgressHierarchy = "PlasmicMainLandingPage2-module--creationProgressHierarchy--cS-zw";
export var dorkSquadInstaLink = "PlasmicMainLandingPage2-module--dorkSquadInstaLink--U90dG";
export var experiences = "PlasmicMainLandingPage2-module--experiences--iAOsp";
export var footBar = "PlasmicMainLandingPage2-module--footBar--buNKd";
export var foreground2 = "PlasmicMainLandingPage2-module--foreground2--vTXCs";
export var freeBox___03Of = "PlasmicMainLandingPage2-module--freeBox___03Of--mmSUD";
export var freeBox___0Spee = "PlasmicMainLandingPage2-module--freeBox___0Spee--DXbPr";
export var freeBox___0Yxx = "PlasmicMainLandingPage2-module--freeBox___0Yxx--cxaPv";
export var freeBox___1XD6O = "PlasmicMainLandingPage2-module--freeBox___1XD6O--ztb12";
export var freeBox___1Zny = "PlasmicMainLandingPage2-module--freeBox___1Zny---7b0l";
export var freeBox___3Ab8Z = "PlasmicMainLandingPage2-module--freeBox___3Ab8Z--q+eLQ";
export var freeBox___3Vj9G = "PlasmicMainLandingPage2-module--freeBox___3Vj9G--HLR19";
export var freeBox___4NrKr = "PlasmicMainLandingPage2-module--freeBox___4NrKr--CtXci";
export var freeBox___6C7I8 = "PlasmicMainLandingPage2-module--freeBox___6C7I8--CT7Np";
export var freeBox___7Kvw0 = "PlasmicMainLandingPage2-module--freeBox___7Kvw0--Iv-gn";
export var freeBox___7TydY = "PlasmicMainLandingPage2-module--freeBox___7TydY--Pp6Y1";
export var freeBox___9Df6T = "PlasmicMainLandingPage2-module--freeBox___9Df6T--JCPgD";
export var freeBox__aawvk = "PlasmicMainLandingPage2-module--freeBox__aawvk--PfQPJ";
export var freeBox__b9F7T = "PlasmicMainLandingPage2-module--freeBox__b9F7T--mqVnl";
export var freeBox__bVpKd = "PlasmicMainLandingPage2-module--freeBox__bVpKd--fzah+";
export var freeBox__cQOe = "PlasmicMainLandingPage2-module--freeBox__cQOe--lZPQ3";
export var freeBox__cQcIg = "PlasmicMainLandingPage2-module--freeBox__cQcIg--tSeIW";
export var freeBox__cYd2G = "PlasmicMainLandingPage2-module--freeBox__cYd2G--JrsSj";
export var freeBox__culso = "PlasmicMainLandingPage2-module--freeBox__culso--Xu-HC";
export var freeBox__dZfOg = "PlasmicMainLandingPage2-module--freeBox__dZfOg--tutg5";
export var freeBox__dpUsc = "PlasmicMainLandingPage2-module--freeBox__dpUsc--Ryw-v";
export var freeBox__ebFvH = "PlasmicMainLandingPage2-module--freeBox__ebFvH--xXzW6";
export var freeBox__ee74Z = "PlasmicMainLandingPage2-module--freeBox__ee74Z--5+LBk";
export var freeBox__elfp = "PlasmicMainLandingPage2-module--freeBox__elfp--NxQbe";
export var freeBox__eou6N = "PlasmicMainLandingPage2-module--freeBox__eou6N--nwZn4";
export var freeBox__eqaR2 = "PlasmicMainLandingPage2-module--freeBox__eqaR2--GkAlX";
export var freeBox__erhU7 = "PlasmicMainLandingPage2-module--freeBox__erhU7--ETd2l";
export var freeBox__etZdJ = "PlasmicMainLandingPage2-module--freeBox__etZdJ--Ej0Lw";
export var freeBox__fUhcb = "PlasmicMainLandingPage2-module--freeBox__fUhcb--W3JC4";
export var freeBox__fjsUc = "PlasmicMainLandingPage2-module--freeBox__fjsUc--XL626";
export var freeBox__gZwVq = "PlasmicMainLandingPage2-module--freeBox__gZwVq--X014+";
export var freeBox__h5ITs = "PlasmicMainLandingPage2-module--freeBox__h5ITs--gOeaK";
export var freeBox__hebj9 = "PlasmicMainLandingPage2-module--freeBox__hebj9--dB3DY";
export var freeBox__iNp7A = "PlasmicMainLandingPage2-module--freeBox__iNp7A--kkXxL";
export var freeBox__jb5S = "PlasmicMainLandingPage2-module--freeBox__jb5S--ZXqjG";
export var freeBox__l8Crc = "PlasmicMainLandingPage2-module--freeBox__l8Crc--I+IpG";
export var freeBox__lgHpI = "PlasmicMainLandingPage2-module--freeBox__lgHpI--oNJL6";
export var freeBox__m9Onq = "PlasmicMainLandingPage2-module--freeBox__m9Onq--T9FfZ";
export var freeBox__mfV4L = "PlasmicMainLandingPage2-module--freeBox__mfV4L--wY0w3";
export var freeBox__miylR = "PlasmicMainLandingPage2-module--freeBox__miylR--jye7n";
export var freeBox__mxP39 = "PlasmicMainLandingPage2-module--freeBox__mxP39--2m2TW";
export var freeBox__n8BdZ = "PlasmicMainLandingPage2-module--freeBox__n8BdZ--IatrJ";
export var freeBox__oTxDu = "PlasmicMainLandingPage2-module--freeBox__oTxDu--OHN7r";
export var freeBox__ocxak = "PlasmicMainLandingPage2-module--freeBox__ocxak--GK7Hk";
export var freeBox__ofUgf = "PlasmicMainLandingPage2-module--freeBox__ofUgf--cZqzX";
export var freeBox__ovl8X = "PlasmicMainLandingPage2-module--freeBox__ovl8X--+M-4O";
export var freeBox__pB0Py = "PlasmicMainLandingPage2-module--freeBox__pB0Py--qSum7";
export var freeBox__pcsLf = "PlasmicMainLandingPage2-module--freeBox__pcsLf--FYS4D";
export var freeBox__pkprk = "PlasmicMainLandingPage2-module--freeBox__pkprk--LIkH-";
export var freeBox__poLaK = "PlasmicMainLandingPage2-module--freeBox__poLaK--3e+-i";
export var freeBox__q5T8O = "PlasmicMainLandingPage2-module--freeBox__q5T8O--QLu8s";
export var freeBox__q8Cn = "PlasmicMainLandingPage2-module--freeBox__q8Cn--akYVb";
export var freeBox__qZ1MQ = "PlasmicMainLandingPage2-module--freeBox__qZ1MQ--soDaV";
export var freeBox__qppd = "PlasmicMainLandingPage2-module--freeBox__qppd--DnW31";
export var freeBox__ru6HK = "PlasmicMainLandingPage2-module--freeBox__ru6HK--uEOmB";
export var freeBox__sEgR = "PlasmicMainLandingPage2-module--freeBox__sEgR--JgU-T";
export var freeBox__sNvf3 = "PlasmicMainLandingPage2-module--freeBox__sNvf3--vA2yH";
export var freeBox__so8Lr = "PlasmicMainLandingPage2-module--freeBox__so8Lr--Xydk5";
export var freeBox__tOzqj = "PlasmicMainLandingPage2-module--freeBox__tOzqj--g6zbF";
export var freeBox__tauQ1 = "PlasmicMainLandingPage2-module--freeBox__tauQ1--+TOfq";
export var freeBox__uAeR = "PlasmicMainLandingPage2-module--freeBox__uAeR--wKy4a";
export var freeBox__uNk2A = "PlasmicMainLandingPage2-module--freeBox__uNk2A--7UBTK";
export var freeBox__vFwUf = "PlasmicMainLandingPage2-module--freeBox__vFwUf--J+xUL";
export var freeBox__vc9Kb = "PlasmicMainLandingPage2-module--freeBox__vc9Kb--TKWtu";
export var freeBox__wEEr7 = "PlasmicMainLandingPage2-module--freeBox__wEEr7--M8+0X";
export var freeBox__wWrIp = "PlasmicMainLandingPage2-module--freeBox__wWrIp--URumT";
export var freeBox__wuIu = "PlasmicMainLandingPage2-module--freeBox__wuIu--UMfRo";
export var freeBox__x4VCn = "PlasmicMainLandingPage2-module--freeBox__x4VCn--xpVdi";
export var freeBox__x8Ff8 = "PlasmicMainLandingPage2-module--freeBox__x8Ff8--S66Ng";
export var freeBox__xrsVq = "PlasmicMainLandingPage2-module--freeBox__xrsVq--wRBSP";
export var freeBox__y2Ssg = "PlasmicMainLandingPage2-module--freeBox__y2Ssg--pDIBE";
export var freeBox__yhPrm = "PlasmicMainLandingPage2-module--freeBox__yhPrm--kWIH8";
export var freeBox__yuc4I = "PlasmicMainLandingPage2-module--freeBox__yuc4I--P9lRj";
export var freeBox__zEjt = "PlasmicMainLandingPage2-module--freeBox__zEjt--RL53Z";
export var freeBox__zToI2 = "PlasmicMainLandingPage2-module--freeBox__zToI2--kpL0n";
export var freeBox__zwDry = "PlasmicMainLandingPage2-module--freeBox__zwDry--KwjOO";
export var gallery = "PlasmicMainLandingPage2-module--gallery--l0Izr";
export var groupPicture = "PlasmicMainLandingPage2-module--groupPicture--CBCQC";
export var h1 = "PlasmicMainLandingPage2-module--h1--7VMQN";
export var h2__i6NYp = "PlasmicMainLandingPage2-module--h2__i6NYp--bn8H4";
export var h2__lZnOt = "PlasmicMainLandingPage2-module--h2__lZnOt--XPu2W";
export var h2__mcpqP = "PlasmicMainLandingPage2-module--h2__mcpqP--3+kcj";
export var h2__qKaXu = "PlasmicMainLandingPage2-module--h2__qKaXu--AKIGZ";
export var h2__s0Eea = "PlasmicMainLandingPage2-module--h2__s0Eea--uy9Cx";
export var h2__xYj6A = "PlasmicMainLandingPage2-module--h2__xYj6A--mVEIx";
export var h2__xvxef = "PlasmicMainLandingPage2-module--h2__xvxef--+MqC1";
export var header = "PlasmicMainLandingPage2-module--header--dR6Aw";
export var httpsstudioplasmicapp = "PlasmicMainLandingPage2-module--httpsstudioplasmicapp--UAeGP";
export var img = "PlasmicMainLandingPage2-module--img--A7uYI";
export var itemsBox = "PlasmicMainLandingPage2-module--itemsBox--I+4bH";
export var latestProject = "PlasmicMainLandingPage2-module--latestProject--CtlWm";
export var link___1X9Te = "PlasmicMainLandingPage2-module--link___1X9Te--vokHV";
export var link___4SbMw = "PlasmicMainLandingPage2-module--link___4SbMw--GQCs7";
export var link__asMkT = "PlasmicMainLandingPage2-module--link__asMkT--zR8+s";
export var link__at97I = "PlasmicMainLandingPage2-module--link__at97I--1FYta";
export var link__czrjl = "PlasmicMainLandingPage2-module--link__czrjl--idNC3";
export var link__e0Fvu = "PlasmicMainLandingPage2-module--link__e0Fvu--SyjkB";
export var link__eErF7 = "PlasmicMainLandingPage2-module--link__eErF7--qPIKT";
export var link__enkQ = "PlasmicMainLandingPage2-module--link__enkQ--u9fj7";
export var link__g9P9N = "PlasmicMainLandingPage2-module--link__g9P9N--ZGaUV";
export var link__go3Se = "PlasmicMainLandingPage2-module--link__go3Se--v48c7";
export var link__grZyM = "PlasmicMainLandingPage2-module--link__grZyM--W5GHD";
export var link__h1Rh = "PlasmicMainLandingPage2-module--link__h1Rh--Ip8FJ";
export var link__i1GbB = "PlasmicMainLandingPage2-module--link__i1GbB--xGFss";
export var link__iPmvP = "PlasmicMainLandingPage2-module--link__iPmvP---M6MJ";
export var link__lS8Ws = "PlasmicMainLandingPage2-module--link__lS8Ws--HiDB-";
export var link__lj9J = "PlasmicMainLandingPage2-module--link__lj9J--dhoyI";
export var link__logxY = "PlasmicMainLandingPage2-module--link__logxY--G8wkA";
export var link__n4MTj = "PlasmicMainLandingPage2-module--link__n4MTj--IDiBm";
export var link__oFcBt = "PlasmicMainLandingPage2-module--link__oFcBt--eX8X7";
export var link__pf6Q = "PlasmicMainLandingPage2-module--link__pf6Q--HuJsg";
export var link__plfxL = "PlasmicMainLandingPage2-module--link__plfxL--LsI60";
export var link__rs0Zw = "PlasmicMainLandingPage2-module--link__rs0Zw--5iyct";
export var link__s4Rwe = "PlasmicMainLandingPage2-module--link__s4Rwe--IICpj";
export var link__sNzd0 = "PlasmicMainLandingPage2-module--link__sNzd0--awGLk";
export var link__sPy9 = "PlasmicMainLandingPage2-module--link__sPy9--ieXjA";
export var link__tAzcc = "PlasmicMainLandingPage2-module--link__tAzcc--cfPO-";
export var link__ugBGj = "PlasmicMainLandingPage2-module--link__ugBGj--kg1vo";
export var link__uizZ2 = "PlasmicMainLandingPage2-module--link__uizZ2--0YnL2";
export var link__vwKtO = "PlasmicMainLandingPage2-module--link__vwKtO--q9Dvr";
export var link__wuLhy = "PlasmicMainLandingPage2-module--link__wuLhy--AK-BZ";
export var link__wxfQg = "PlasmicMainLandingPage2-module--link__wxfQg--VKnRz";
export var link__x7Umg = "PlasmicMainLandingPage2-module--link__x7Umg--Atp0N";
export var link__zZHt = "PlasmicMainLandingPage2-module--link__zZHt--XMOcJ";
export var mailtoinfodorksquadorg = "PlasmicMainLandingPage2-module--mailtoinfodorksquadorg--tPhsf";
export var openMailapp = "PlasmicMainLandingPage2-module--openMailapp--yjE6G";
export var recruting = "PlasmicMainLandingPage2-module--recruting--0Mowb";
export var reviews = "PlasmicMainLandingPage2-module--reviews--ck2Sc";
export var root = "PlasmicMainLandingPage2-module--root--Fevfe";
export var services = "PlasmicMainLandingPage2-module--services--xGTwK";
export var svg___0WStM = "PlasmicMainLandingPage2-module--svg___0WStM--L1w9p";
export var svg___1CkqK = "PlasmicMainLandingPage2-module--svg___1CkqK--6ibUf";
export var svg___329Q8 = "PlasmicMainLandingPage2-module--svg___329Q8--yYv98";
export var svg___5HriG = "PlasmicMainLandingPage2-module--svg___5HriG--y5Z4O";
export var svg___5PsGd = "PlasmicMainLandingPage2-module--svg___5PsGd--ARDVR";
export var svg___651AQ = "PlasmicMainLandingPage2-module--svg___651AQ--i63Ay";
export var svg___72UZ2 = "PlasmicMainLandingPage2-module--svg___72UZ2--hvq+B";
export var svg___773Zn = "PlasmicMainLandingPage2-module--svg___773Zn--6iGIs";
export var svg___7Wltm = "PlasmicMainLandingPage2-module--svg___7Wltm--exunj";
export var svg___8B78Z = "PlasmicMainLandingPage2-module--svg___8B78Z--bJpjA";
export var svg__aPD = "PlasmicMainLandingPage2-module--svg__aPD--HJtDI";
export var svg__aZ3Nb = "PlasmicMainLandingPage2-module--svg__aZ3Nb--mwteg";
export var svg__apmcY = "PlasmicMainLandingPage2-module--svg__apmcY--RWrqZ";
export var svg__b2BuI = "PlasmicMainLandingPage2-module--svg__b2BuI--zHIah";
export var svg__by32S = "PlasmicMainLandingPage2-module--svg__by32S--TQLaX";
export var svg__c6Qed = "PlasmicMainLandingPage2-module--svg__c6Qed--lEX1U";
export var svg__d1Inx = "PlasmicMainLandingPage2-module--svg__d1Inx--XtU4A";
export var svg__eR4Bh = "PlasmicMainLandingPage2-module--svg__eR4Bh--JsFEy";
export var svg__gKdoL = "PlasmicMainLandingPage2-module--svg__gKdoL--oc3Lm";
export var svg__gdClb = "PlasmicMainLandingPage2-module--svg__gdClb--OGMLk";
export var svg__gjG5Z = "PlasmicMainLandingPage2-module--svg__gjG5Z--6x-mM";
export var svg__hA4Ny = "PlasmicMainLandingPage2-module--svg__hA4Ny--gQkX+";
export var svg__i99K6 = "PlasmicMainLandingPage2-module--svg__i99K6--NcqQy";
export var svg__iyKLi = "PlasmicMainLandingPage2-module--svg__iyKLi--Batpv";
export var svg__jDafG = "PlasmicMainLandingPage2-module--svg__jDafG--82YGi";
export var svg__jz1NK = "PlasmicMainLandingPage2-module--svg__jz1NK--T5wLd";
export var svg__kaEHa = "PlasmicMainLandingPage2-module--svg__kaEHa--3QU+3";
export var svg__l04Cc = "PlasmicMainLandingPage2-module--svg__l04Cc--Sy+Nx";
export var svg__lR5L = "PlasmicMainLandingPage2-module--svg__lR5L--1T6xv";
export var svg__lhKB = "PlasmicMainLandingPage2-module--svg__lhKB--I6zHT";
export var svg__m1Tpt = "PlasmicMainLandingPage2-module--svg__m1Tpt--ZJ3na";
export var svg__mPo4T = "PlasmicMainLandingPage2-module--svg__mPo4T--5gwoc";
export var svg__mcLgN = "PlasmicMainLandingPage2-module--svg__mcLgN--ttAKR";
export var svg__pevOp = "PlasmicMainLandingPage2-module--svg__pevOp--3VOWo";
export var svg__qCAbN = "PlasmicMainLandingPage2-module--svg__qCAbN--pf80d";
export var svg__qWdFi = "PlasmicMainLandingPage2-module--svg__qWdFi--zao64";
export var svg__s73WX = "PlasmicMainLandingPage2-module--svg__s73WX--BZm3o";
export var svg__sAgTj = "PlasmicMainLandingPage2-module--svg__sAgTj--Ki8-B";
export var svg__t00Is = "PlasmicMainLandingPage2-module--svg__t00Is--3nSYl";
export var svg__tnyVw = "PlasmicMainLandingPage2-module--svg__tnyVw--ty4GH";
export var svg__tp7F = "PlasmicMainLandingPage2-module--svg__tp7F--f40Oj";
export var svg__ttqEl = "PlasmicMainLandingPage2-module--svg__ttqEl--8qDVg";
export var svg__u9Z9B = "PlasmicMainLandingPage2-module--svg__u9Z9B--nAbFj";
export var svg__uRrU4 = "PlasmicMainLandingPage2-module--svg__uRrU4--HWrCH";
export var svg__vq8NC = "PlasmicMainLandingPage2-module--svg__vq8NC--duOGP";
export var svg__wdZqT = "PlasmicMainLandingPage2-module--svg__wdZqT--X977O";
export var svg__xYlVx = "PlasmicMainLandingPage2-module--svg__xYlVx--mSjMA";
export var svg__xaOyS = "PlasmicMainLandingPage2-module--svg__xaOyS--rJZ7h";
export var svg__xuCjq = "PlasmicMainLandingPage2-module--svg__xuCjq--pc7CT";
export var svg__z6Xv9 = "PlasmicMainLandingPage2-module--svg__z6Xv9--6641F";
export var svg__zDy8I = "PlasmicMainLandingPage2-module--svg__zDy8I--IY654";
export var svg__zaZHk = "PlasmicMainLandingPage2-module--svg__zaZHk--3QN54";
export var svg__zcukp = "PlasmicMainLandingPage2-module--svg__zcukp--aIQ4G";
export var svg__zl5Tq = "PlasmicMainLandingPage2-module--svg__zl5Tq--HHYTu";
export var textInput__exnR = "PlasmicMainLandingPage2-module--textInput__exnR--qKAW3";
export var textInput__v3Ur5 = "PlasmicMainLandingPage2-module--textInput__v3Ur5--qqYWi";
export var text__d5GeF = "PlasmicMainLandingPage2-module--text__d5GeF--Xhh0o";
export var text__dIr1Z = "PlasmicMainLandingPage2-module--text__dIr1Z--DZkHe";
export var text__dUwko = "PlasmicMainLandingPage2-module--text__dUwko--UB-Mt";
export var text__dnvy8 = "PlasmicMainLandingPage2-module--text__dnvy8--1vJI+";
export var text__eZbm = "PlasmicMainLandingPage2-module--text__eZbm--C8ni5";
export var text__gjkKg = "PlasmicMainLandingPage2-module--text__gjkKg--AHduS";
export var text__iPup3 = "PlasmicMainLandingPage2-module--text__iPup3--4+a4r";
export var text__iViwM = "PlasmicMainLandingPage2-module--text__iViwM--E2gc1";
export var text__idt3W = "PlasmicMainLandingPage2-module--text__idt3W--zGUsb";
export var text__kIpfP = "PlasmicMainLandingPage2-module--text__kIpfP--6dmV-";
export var text__kd3XO = "PlasmicMainLandingPage2-module--text__kd3XO--3Faka";
export var text__nSjZp = "PlasmicMainLandingPage2-module--text__nSjZp--mQffJ";
export var text__o5Ono = "PlasmicMainLandingPage2-module--text__o5Ono--jNW-D";
export var text__pi5Pt = "PlasmicMainLandingPage2-module--text__pi5Pt--mRzsl";
export var text__qoHyl = "PlasmicMainLandingPage2-module--text__qoHyl--BTcmU";
export var text__sfUr = "PlasmicMainLandingPage2-module--text__sfUr--llpri";
export var text__v6PfE = "PlasmicMainLandingPage2-module--text__v6PfE--TENjh";
export var text__v9Rc = "PlasmicMainLandingPage2-module--text__v9Rc--rqVAC";
export var text__vwAWp = "PlasmicMainLandingPage2-module--text__vwAWp--mEQ+C";
export var text__x5Mvv = "PlasmicMainLandingPage2-module--text__x5Mvv--+tNT4";
export var text__xWpkb = "PlasmicMainLandingPage2-module--text__xWpkb--y-+9J";
export var text__xnDxC = "PlasmicMainLandingPage2-module--text__xnDxC--haT3A";
export var text__yesV = "PlasmicMainLandingPage2-module--text__yesV--V-YDM";
export var titleBox = "PlasmicMainLandingPage2-module--titleBox--2UocB";
export var verticalStack = "PlasmicMainLandingPage2-module--verticalStack--M4InP";
export var whatWeStandFor = "PlasmicMainLandingPage2-module--whatWeStandFor--BJ0vy";